import { ComponentType, useEffect } from "react";

import { useEvent } from "effector-react/scope";

import { Box } from "@chakra-ui/react";

import Fetching from "components/fetching";

import { sessionModel } from "models/keycloak";

import { KeycloakProfile } from "keycloak-js";

import { useUser } from "./hooks";

export type WithPageAuthRequired = <P>(
  Component: ComponentType<P & { user: KeycloakProfile }>
) => React.FC<P>;

export const withPageAuthRequired: WithPageAuthRequired = (Component) => {
  return function WithPageAuthRequired(props): JSX.Element {
    const authPageMounted = useEvent(sessionModel.authPageMounted);
    const { user, isLoading, error } = useUser();
    useEffect(authPageMounted, []);

    if (isLoading) {
      return (
        <Box height="100vh">
          <Fetching message="Establishing session..." />
        </Box>
      );
    }

    if (user) return <Component user={user} {...props} />;

    if (error) return <div>Error</div>;

    return (
      <Box height="100vh">
        <Fetching message="Redirecting you to the login..." />
      </Box>
    );
  };
};
