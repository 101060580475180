import { createContext, useContext } from "react";
import Head from "next/head";

import { useDisclosure, UseDisclosureReturn } from "@chakra-ui/hooks";
import { Box, BoxProps } from "@chakra-ui/layout";
import { useColorModeValue } from "@chakra-ui/react";

import Header from "components/header/header";

import { useUser } from "models/keycloak";

import { noop } from "shared/lib/fn";
import { useCallbackRef } from "shared/lib/hooks/use-callback-ref";

type LayoutProps = {
  title?: string | React.ReactElement;
  loading: boolean;
  children: React.ReactNode;
};

const Context = createContext<HTMLDivElement | undefined>(undefined);

export function useBodyRef() {
  return useContext(Context);
}

const SelectSourceMapDisclosureContext = createContext<
  Partial<UseDisclosureReturn>
>({
  isOpen: false,
  onOpen: noop,
  onClose: noop,
  onToggle: noop,
});

export function useSelectSourceMapDisclosure() {
  return useContext(SelectSourceMapDisclosureContext);
}

const Layout: React.FunctionComponent<LayoutProps & Omit<BoxProps, "title">> =
  ({ title = "mapflow.ai", loading = false, children, ...layoutProps }) => {
    const { user } = useUser();

    const [setBodyRef, value] = useCallbackRef<HTMLDivElement>();

    const bg = useColorModeValue("gray.100", "gray.900");

    const disclosure = useDisclosure();

    return (
      <>
        <div>
          <SelectSourceMapDisclosureContext.Provider value={disclosure}>
            <Head>
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1"
              />
              <title>{title}</title>
            </Head>

            <Header loading={loading} user={user} />

            <Context.Provider value={value}>
              <Box ref={setBodyRef} position="relative">
                <Box mt="var(--header-height)" position="relative">
                  <Box
                    bg={bg}
                    as="section"
                    height="calc(100vh - var(--header-height))"
                    overflow="auto"
                    {...layoutProps}
                  >
                    <Box
                      minHeight="calc(100vh - var(--header-height))"
                      height="100%"
                    >
                      {children}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Context.Provider>
          </SelectSourceMapDisclosureContext.Provider>
        </div>
      </>
    );
  };

export default Layout;
