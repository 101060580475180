import React from "react";

import { t } from "@lingui/macro";
import { Trans } from "@lingui/react";
import { convertArea, Units } from "@turf/helpers";

import { Text, TextProps } from "@chakra-ui/layout";

type TUnitsTranslates = Extract<Units, "meters" | "kilometers">;

const unitTranslates: Record<TUnitsTranslates, string> = {
  meters: t`m<0>2</0>`,
  kilometers: t`km<0>2</0>`,
};

const getUnits = (i: number): TUnitsTranslates => {
  return i === 0 ? "meters" : "kilometers";
};
const k = 1_0000;

type unitsToDemicals = Partial<Record<Units, number>>;

const unitsMap: unitsToDemicals = { meters: 0, kilometers: 2 };

export function formatArea(
  area: number,
  fixed: number | unitsToDemicals = 2
): [string, TUnitsTranslates] {
  if (area === 0) return ["0", "meters"];
  const i = Math.floor(Math.log(area) / Math.log(k));
  const to = getUnits(i);
  const fx = typeof fixed === "number" ? (fixed > 0 ? fixed : 0) : fixed[to];
  const result = convertArea(area, "meters", to).toFixed(fx);
  return [result, to];
}

function cutTailZeroes(z: string): string {
  const [head, tail] = z.split(".");
  if (parseInt(tail) === 0) return head;
  return z;
}

type Props = TextProps & {
  area: number;
  demicals?: number | unitsToDemicals;
  cutZeros?: boolean;
};

const FormatArea: React.FC<Props> = ({
  area,
  demicals = unitsMap,
  cutZeros = false,
  ...textProps
}) => {
  const [value, u] = formatArea(area, demicals);
  const id = unitTranslates[u];
  const a = cutZeros ? cutTailZeroes(value.toString()) : value;
  return (
    <Text as="span" {...textProps}>
      {a} <Trans id={id} components={[<sup />]} />
    </Text>
  );
};

export default FormatArea;
