import { FC, ReactNode } from "react";

import { CircularProgress } from "@chakra-ui/progress";

import NotIdealState from "components/not-ideal-state";

const Fetching: FC<{ message: ReactNode }> = ({ message }) => (
  <NotIdealState spacing="4" title={message}>
    <CircularProgress capIsRound isIndeterminate />
  </NotIdealState>
);

export default Fetching;
