import { VFC } from "react";

import { Trans } from "@lingui/macro";

import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertProps,
  AlertTitle,
  Box,
} from "@chakra-ui/react";

import { KeycloakUser } from "models/keycloak";

import { Maybe } from "types";

type Props = AlertProps & { user?: Maybe<KeycloakUser> };

const EmailVerificationNotice: VFC<Props> = ({ user, ...props }) => {
  // const toast = useToast();

  const isEmailVerified = user?.email_verified || true;

  // useEffect(() => {
  //   if (isEmailVerified) return;

  //   if (typeof window !== "undefined") {
  //     toast({
  //       title: t`Please verify your email`,
  //       description: t`A confirmation link has been sent to your email`,
  //       status: "info",
  //       duration: null,
  //       isClosable: false,
  //       position: "top",
  //     });
  //   }
  // }, [isEmailVerified, is767]);

  if (isEmailVerified) return null;

  return (
    <Alert
      status="info"
      width="fit-content"
      p="0.3rem 0.5rem"
      borderRadius="0.5rem"
      variant="solid"
      {...props}
    >
      <AlertIcon />
      <Box>
        <AlertTitle fontSize="sm" lineHeight="short">
          <Trans>Please verify your email</Trans>
        </AlertTitle>
        <AlertDescription fontSize="sm" lineHeight="normal" display="block">
          <Trans>A confirmation link has been sent to your email</Trans>
        </AlertDescription>
      </Box>
    </Alert>
  );
};

export default EmailVerificationNotice;
