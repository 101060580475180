import { useEffect } from "react";
import { publicRuntimeConfig } from "config";

import { Trans } from "@lingui/macro";

import { useColorModeValue } from "@chakra-ui/color-mode";
import { useDisclosure } from "@chakra-ui/hooks";
import {
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
} from "@chakra-ui/react";

import { useUser } from "models/keycloak";

import { useCookieState } from "use-cookie-state";

const initialBalance = publicRuntimeConfig.INIT_BALANCE;

export const FreeCoinPortal: React.FC = ({ children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [showTopUpMessage, setShowTopUpMessage] = useCookieState<
    "visible" | "invisible"
  >("wellcome-topup-message", "visible", {
    encodeOps: { path: "/", expires: new Date("10000") },
  });

  const { user } = useUser();

  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      showTopUpMessage === "visible" &&
      user?.logins_count === 1 // only on first login
    )
      setTimeout(() => {
        onOpen();
      }, 1000);
  }, []);

  const bgColor = useColorModeValue("white", "green.800");
  const textColor = useColorModeValue("gray.900", "white");
  const titleTextColor = useColorModeValue("green.500", "white");

  const handleClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    onClose();
    setShowTopUpMessage("invisible");
  };

  return (
    <Popover isOpen={isOpen} placement="bottom-end">
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent
        zIndex={4}
        color={textColor}
        bg={bgColor}
        borderColor="green.500"
        borderRadius="ui-base"
      >
        <PopoverArrow />
        <PopoverHeader
          p={4}
          fontSize="lg"
          fontWeight={700}
          color={titleTextColor}
        >
          <Trans>You've got a free credits!</Trans>
        </PopoverHeader>
        <PopoverBody p={4}>
          <Trans>You received</Trans> {initialBalance}{" "}
          <Trans>free credits to get started with Mapflow!</Trans>
        </PopoverBody>
        <PopoverFooter
          border="0"
          d="flex"
          alignItems="center"
          justifyContent="flex-end"
          pb={4}
        >
          <Button colorScheme="green" onClick={handleClose}>
            <Trans>Got it!</Trans>
          </Button>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
};
