import { useCallback } from "react";
import { useRouter } from "next/router";

export const useUpdateQueryParams = () => {
  const router = useRouter();

  const updateQueryParams = useCallback(
    (newQueryParams: Record<string, any>): void => {
      const currentQueryParams = router.query;

      const updatedQueryParams: Record<string, any> = {
        ...currentQueryParams,
        ...newQueryParams,
      };

      // Remove keys with null or undefined values
      Object.keys(updatedQueryParams).forEach((key) => {
        if (
          updatedQueryParams[key] === null ||
          updatedQueryParams[key] === undefined ||
          updatedQueryParams[key] === ""
        ) {
          delete updatedQueryParams[key];
        }
      });

      router.replace(
        {
          pathname: router.pathname,
          query: updatedQueryParams,
        },
        undefined,
        { shallow: true }
      );
    },
    [router]
  );

  return updateQueryParams;
};

export const useClearQueryParams = () => {
  const router = useRouter();
  const clearQuery = useCallback(() => {
    router.replace(
      {
        pathname: router.pathname,
        query: {},
      },
      undefined,
      { shallow: true }
    );
  }, [router]);

  return clearQuery;
};
