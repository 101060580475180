import { useEffect, useMemo, useState } from "react";
import Link from "next/link";
import { useRouter } from "next/router";

import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";

import { useColorMode } from "@chakra-ui/color-mode";
import { Box, Flex } from "@chakra-ui/layout";
import {
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/react";

import { FiSearch } from "@react-icons/all-files/fi/FiSearch";
import { FiX } from "@react-icons/all-files/fi/FiX";

import UserButton from "components/header/user-button";
import { useSelectSourceMapDisclosure } from "components/layout";
import Logo from "components/logo";

import { KeycloakUser } from "models/keycloak";

import { useDebounce } from "shared/lib/hooks/use-debounce";
import { useUpdateQueryParams } from "shared/lib/hooks/use-update-query-params";

import EmailVerificationNotice from "../email-verification-notice";
import { UserBalance } from "./user-balance";

const placeholderText = {
  project: t`Search for a processings`,
  projects: t`Search for a projects`,
  sourceMap: t`Search for a map location`,
  default: t`Press to search`,
};

const bg = { light: "white", dark: "gray.800" };

const projectRegexp = /\/projects\/([^/]+)$/i;

type HeaderProps = {
  user?: KeycloakUser | null;
  loading: boolean;
};

const Header: React.VFC<HeaderProps> = (props) => {
  const router = useRouter();
  const { i18n } = useLingui();
  const { user, loading, ...rest } = props;
  const { colorMode } = useColorMode();

  const [search, setSearch] = useState(router.query?.search || "");
  const debouncedSearch = useDebounce(search, 500);

  const updateQueryParams = useUpdateQueryParams();

  useEffect(() => {
    if (debouncedSearch && debouncedSearch !== router.query.search) {
      updateQueryParams({
        search: debouncedSearch || undefined,
        page: 1,
        pageSize: 10,
      });
    }
  }, [debouncedSearch]);

  const pathname = router.pathname;

  const isProjectPage = useMemo(() => projectRegexp.test(pathname), [pathname]);

  const sourceMapStatus = useSelectSourceMapDisclosure();

  const isProjectsPage = useMemo(() => pathname === "/projects", [pathname]);
  const isAccountPage = useMemo(() => pathname === "/account", [pathname]);
  const isSourceMap = sourceMapStatus.isOpen;

  const inputHidden = isAccountPage || isProjectPage;

  const placeholderState = useMemo(() => {
    if (isSourceMap) return "sourceMap";
    if (isProjectsPage) return "projects";
    if (isProjectPage) return "project";

    return "default";
  }, [isProjectsPage, isSourceMap, isProjectPage]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearch(value);
  };

  const onClear = () => {
    setSearch("");
    updateQueryParams({ search: "" });
  };

  return (
    <Box
      pos="fixed"
      as="header"
      top="0"
      zIndex={200}
      bg={bg[colorMode]}
      left="0"
      right="0"
      borderBottomWidth="1px"
      width="full"
      height="var(--header-height)"
      {...rest}
    >
      <Box width="full" mx="auto" px={6} height="100%">
        <Flex h="full" w="full" align="center" justify="space-between">
          <Flex h="full" w="full" align="center" justify="flex-start">
            <Box d="block" aria-label="Back to homepage">
              <Link href="/projects">
                <a>
                  <Logo />
                </a>
              </Link>
            </Box>

            <Box
              flex="1"
              display={["none", null, "block"]}
              maxW="550px"
              ml={[null, null, 10, 16]}
              mr={[null, null, 4, 8]}
            >
              {inputHidden
                ? null
                : !isSourceMap && (
                    <InputGroup flex={1}>
                      <InputLeftElement children={<FiSearch />} />
                      <Input
                        data-cy="header-search-input"
                        flex={1}
                        type="text"
                        borderRadius="ui-base"
                        value={search}
                        onChange={handleSearchChange}
                        placeholder={i18n._(placeholderText[placeholderState])}
                        bg={colorMode === "light" ? "gray.100" : "gray.700"}
                      />
                      <InputRightElement
                        data-cy="header-search-clear-button"
                        cursor="pointer"
                        children={<FiX />}
                        visibility={
                          typeof search === "string" && search !== ""
                            ? "visible"
                            : "hidden"
                        }
                        onClick={onClear}
                      />
                    </InputGroup>
                  )}
            </Box>
          </Flex>
          {!loading && (
            <EmailVerificationNotice
              display={["none", "none", "none", "flex"]}
              flexShrink={0}
              mr="1rem"
              user={props.user}
            />
          )}
          <Flex align="center" color="gray.500">
            {user && <UserBalance />}
            <Box mr={5} />
            <UserButton />
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};

export default Header;
