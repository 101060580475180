import { AxiosError } from "axios";
import { useQuery } from "react-query";
import { useEvent } from "effector-react";

import wizardModel from "models/wizard";

import { TeamStatus } from "types";

import { fetchTileURLFromCOG, fetchUserStatus } from ".";

export const useUserInfo = () => {
  const setUserInfo = useEvent(wizardModel.events.setUserInfo);

  return useQuery<TeamStatus, AxiosError>("user-status", fetchUserStatus, {
    onSuccess: (data) => {
      setUserInfo(data);
    },
    onError: console.error,
  });
};

export const useTileFromCOG = (processingId: string, url?: string) => {
  return useQuery(
    ["tiff-raster-cog", processingId],
    () => {
      if (!url) return;
      return fetchTileURLFromCOG(url);
    },
    {
      enabled: Boolean(url),
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
    }
  );
};

// export const useSchema = (
//   options: UseQueryOptions<Schema, AxiosError> = {}
// ) => {
//   const setSchema = useEvent(wizardModel.events.setSchema);

//   const query = useQuery<Schema, AxiosError>("schema", () => fetchSchema(), {
//     onSuccess: setSchema,
//     refetchOnWindowFocus: false,
//     ...options,
//   });

//   return query;
// };
