import { ElementType, ReactNode } from "react";

import { Trans } from "@lingui/react";

import { Box, BoxProps, Flex, Heading, Stack, Text } from "@chakra-ui/layout";

import { IconType } from "@react-icons/all-files";

const NotIdealState: React.FC<{
  title: string | ReactNode | typeof Trans;
  icon?: IconType | ElementType;
  iconProps?: BoxProps;
  description?: string | ReactNode | typeof Trans;
  spacing?: string | number;
  action?: ReactNode;
}> = ({
  children,
  icon,
  title,
  description,
  iconProps = {},
  spacing = "3",
  action = null,
}) => {
  return (
    <Flex
      height="100%"
      minHeight="100%"
      direction="column"
      justifyContent="center"
      alignItems="center"
      data-cy="not-ideal-state"
    >
      <Stack spacing={spacing} justifyContent="center" alignItems="center">
        {icon ? <Box boxSize="24" {...iconProps} as={icon} /> : children}
        <Heading size="md">{title}</Heading>
        {description && <Text>{description}</Text>}
        {action}
      </Stack>
    </Flex>
  );
};

export default NotIdealState;
