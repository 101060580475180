import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuDivider,
  MenuList,
} from "@chakra-ui/react";

import { useUser } from "models/keycloak";

import {
  DocsLinkMenuItem,
  LogoutMenuItem,
  MapflowLinkMenuItem,
  SettingsMenuItem,
  UserAvatar,
  UserMenuItem,
} from "./menu-items";

function UserButton() {
  const { user } = useUser();

  if (user == null) return <Button isLoading {...userButtonStyles} />;

  return (
    <Menu autoSelect={false} closeOnSelect={false}>
      <MenuButton as={Button} {...userButtonStyles}>
        <UserAvatar user={user} />
      </MenuButton>
      <MenuList p="0" width="310px" borderRadius="ui-base">
        <Flex p="1" direction="column">
          <UserMenuItem user={user} />
          <SettingsMenuItem />
          <DocsLinkMenuItem />
          <MapflowLinkMenuItem />
        </Flex>
        <MenuDivider
          h="2px"
          w="full"
          my="0"
          bg="gray.300"
          borderBottom="none"
        />
        <Flex p="1">
          <LogoutMenuItem />
        </Flex>
      </MenuList>
    </Menu>
  );
}

const userButtonStyles = {
  _hover: { filter: "brightness(1.35)" },
  w: "44px",
  h: "44px",
  rounded: "50%",
  outline: "none",
  padding: 0,
};

export default UserButton;
