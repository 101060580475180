import React from "react";
import Link from "next/link";

import { Box, Tag, TagLabel, TagLeftIcon } from "@chakra-ui/react";

import { FaCoins } from "@react-icons/all-files/fa/FaCoins";
import { FaRuler } from "@react-icons/all-files/fa/FaRuler";

import FormatArea from "components/format-area";

import { useUserInfo } from "shared/api/client/hook";

import { BillingType } from "types";

import { FreeCoinPortal } from "./free-coin-portal";

const balanceIconsByBilingType = {
  [BillingType.credits]: FaCoins,
  [BillingType.area]: FaRuler,
  [BillingType.none]: null,
};

export const UserBalance = () => {
  const { data } = useUserInfo();
  const costsByBilingTypes = {
    [BillingType.credits]: data?.remainingCredits,
    [BillingType.area]: data?.remainingArea,
    [BillingType.none]: null,
  };
  const cost = data ? costsByBilingTypes[data.billingType] : 0;

  if (data && cost !== null)
    return (
      <Link passHref href="/account/balance">
        <Box
          as="a"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            pointerEvents:
              data.billingType === BillingType.credits ? "auto" : "none",
          }}
        >
          <FreeCoinPortal>
            <Box>
              <Tag
                colorScheme="blue"
                borderRadius="ui-base"
                fontSize="lg"
                fontWeight={600}
                cursor="pointer"
                p="10px 12px"
              >
                <TagLeftIcon
                  as={
                    balanceIconsByBilingType[data.billingType] ||
                    balanceIconsByBilingType.AREA
                  }
                  boxSize="12px"
                />
                <TagLabel>
                  {data.billingType === BillingType.area && cost ? (
                    <FormatArea area={cost} />
                  ) : (
                    cost
                  )}
                </TagLabel>
              </Tag>
            </Box>
          </FreeCoinPortal>
        </Box>
      </Link>
    );

  return null;
};
