import { useCallback, useState } from "react";

export function useCallbackRef<T>({
  onRefReady,
}: { onRefReady?: (ref: any) => void } = {}): [
  ref: (instance: HTMLDivElement | null) => void,
  value: T | undefined
] {
  const [value, set] = useState<T>();

  const ref = useCallback((node) => {
    if (node !== null)
      set(() => {
        onRefReady && onRefReady(node);
        return node;
      });
  }, []);

  return [ref, value];
}
